<template>
  <div class="profile-form">
    <ng-fieldset :fields="formFields" v-model="formModel">
      <template v-slot:[`field.newPasswordConfirm`]="{ field, formValue }">
        <ng-text-field
          class="pb-4"
          v-show="formValue.newPassword.length"
          :label="field.label"
          :name="field.name"
          filled
          type="password"
          v-model="formValue.newPasswordConfirm"
          :error="!!field.errors.length"
          :error-messages="field.errors"
        ></ng-text-field>
      </template>
    </ng-fieldset>
    <ng-button
      ref="submitButton"
      class="mt-4"
      type="submit"
      color="primary"
      large
      @click.prevent="submit()"
    >
      {{ $t('save') }}
    </ng-button>
  </div>
</template>

<i18n>
  {
    "en": {
      "save": "Save"
    },
    "ru": {
      "save": "Сохранить"
    }
  }
</i18n>

<script>
import { validationMixin } from 'vuelidate';
import vuelidatable from '@ngservices_front/mixins/vuelidatable';
import { required, email, sameAs, minLength } from 'vuelidate/lib/validators';
import { username } from '@ngservices_front/js/validators';
import { phoneNumber } from '@ngservices_front/js/phoneValidator';
import { getIndustriesSorted } from '@/enhancers/useIndustries';

export default {
  mixins: [validationMixin, vuelidatable],
  props: {
    formElId: {
      // TODO: remove after moving to vue form completely
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formFields: null,
      formModel: null,
    };
  },
  watch: {
    vuelidateErrors: {
      deep: true,
      handler() {
        this.setErrorsTo(this.formFields, { validationRoot: 'formModel' });
      },
    },
  },
  validations: {
    formModel: {
      username: { required, username },
      email: { required, email },
      phone: { phoneNumber },
      newPassword: {
        minLength: minLength(6),
      },
      newPasswordConfirm: {
        sameAs: sameAs(function () {
          return this.formModel && this.formModel.newPassword;
        }),
      },
    },
  },
  computed: {
    formEl() {
      return document.getElementById(this.formElId);
    },
  },
  created() {
    window.profileFormFields.industry.options = getIndustriesSorted();
    this.formFields = window.profileFormFields;
    this.formModel = window.profileFormModel;
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.formEl.submit();
      } else {
        this.$vuetify.goTo(this.formEl, { offset: 100 });
      }
    },
  },
};
</script>

<style lang="scss"></style>
