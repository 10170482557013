import i18n from '@/i18n/';

const INDUSTRIES = [
  { value: 'agriculture', text: i18n.t('industries.agriculture') },
  { value: 'forestry', text: i18n.t('industries.forestry') },
  { value: 'environment', text: i18n.t('industries.environment') },
  { value: 'architecture', text: i18n.t('industries.architecture') },
  { value: 'it', text: i18n.t('industries.it') },
  { value: 'retail', text: i18n.t('industries.retail') },
  { value: 'watermanagement', text: i18n.t('industries.watermanagement') },
  { value: 'hunt', text: i18n.t('industries.hunt') },
  { value: 'funeral', text: i18n.t('industries.funeral') },
  { value: 'telecom', text: i18n.t('industries.telecom') },
  { value: 'realestate', text: i18n.t('industries.realestate') },
  { value: 'transport', text: i18n.t('industries.transport') },
  { value: 'cadastre', text: i18n.t('industries.cadastre') },
  { value: 'utilities', text: i18n.t('industries.utilities') },
  { value: 'tourism', text: i18n.t('industries.tourism') },
  { value: 'uav', text: i18n.t('industries.uav') },
  { value: 'finances', text: i18n.t('industries.finances') },
  { value: 'manufacturing', text: i18n.t('industries.manufacturing') },
  { value: 'mining', text: i18n.t('industries.mining') },
  { value: 'energy', text: i18n.t('industries.energy') },
  { value: 'history', text: i18n.t('industries.history') },
  { value: 'healthcare', text: i18n.t('industries.healthcare') },
  { value: 'emergencies', text: i18n.t('industries.emergencies') },
  { value: 'elections', text: i18n.t('industries.elections') },
  { value: 'media', text: i18n.t('industries.media') },
  { value: 'mapping', text: i18n.t('industries.mapping') },
  { value: 'datascience', text: i18n.t('industries.datascience') },
];

const getIndustriesSorted = () => {
  return [
    ...INDUSTRIES.sort((a, b) => {
      if (a.text < b.text) return -1;
      if (a.text > b.text) return -1;
      return 0;
    }),
    { value: 'other', text: i18n.t('app.other') },
  ];
};

export { INDUSTRIES, getIndustriesSorted };
